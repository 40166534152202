const DocMenuConfig = [
  {
    pages: [
      {
        heading: "Dashboard",
        route: "/dashboard",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Pedidos",
        route: "/apps/pedidos/pedidos-listing",
        svgIcon: "media/icons/duotune/art/art006.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Clientes",
        route: "/apps/clients/clients-listing",
        svgIcon: "media/icons/duotune/art/art006.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Produtos",
        route: "/apps/products/products-listing",
        svgIcon: "media/icons/duotune/art/art006.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Forma de Pagamentos",
        route: "/apps/forma_pagamentos/forma_pagamentos-listing",
        svgIcon: "media/icons/duotune/art/art006.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Entregadores",
        route: "/apps/entregadores/entregadores-listing",
        svgIcon: "media/icons/duotune/art/art006.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
];

export default DocMenuConfig;