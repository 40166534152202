import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold py-4 fs-6 w-275px",
  "data-kt-menu": "true"
}
const _hoisted_2 = { class: "menu-item px-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.signOut())),
        class: "menu-link px-5"
      }, " Sair ")
    ])
  ]))
}