
import { defineComponent } from "vue";
import Dropdown1 from "@/components/dropdown/Dropdown1.vue";
import { toolbarWidthFluid } from "@/core/helpers/config";

export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    title: String,
    whats: String,
    spinner: Boolean,
  },
  methods: {

    salvarPedido : function(){

      this.$emit('salvarPedido');

    },

     setarSelecionado(endereco_id){

       
      setTimeout(() => {
         window.location.reload()
      }, 1000);

    },

    preparaWhats : function(){

      this.$emit('preparaWhats');

    }

  },
  components: {
    Dropdown1,
  },
  setup() {
    return {
      toolbarWidthFluid,
    };
  },
});
